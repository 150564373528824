.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.communication .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
}

.communication button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.communication button:hover {
    background-color: red;
}

.communication .messageBox p {
    color: red;
}
.communication .messageBox.negative p {
    color: red;
}
.communication .messageBox.pozitive p {
    color: green;
}
.communication .messageBox button {
    background-color: lightgray;
}
.communication .messageBox button:hover {
    background-color: red;
}

.communication .layout .activityIndicatorContainer {
    height: 80vh;
}


.communication .motivation.card .content {
    padding: 0 20px;
}
.communication .motivation.card .content p:last-child {
    font-weight: bold;
}

.communicationMessageList {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    margin-top: 40px;
}
.communicationMessageList li {
    margin-bottom: 30px;
}
.communicationMessageList li p {
    white-space: pre-line;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    /* background-color: #fff; */
}
.communicationMessageList li.client {
    /* width: 80%; */
    /* align-self: end; */
    /* display: flex;
    flex-direction: column;
    gap: 10px; */

    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 20px;
    row-gap: 10px;
}
.communicationMessageList li .image img {
    width: 48px;
}
.communicationMessageList li.client .image {
    grid-column: 1;
    grid-row: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    /* border: solid 1px #cccccc; */
    overflow: hidden;
    background-color: #fff;
}
.communicationMessageList li.client p {
    /* background-color: #d1f1dc; */
    border: solid 1pt #78C700;
    border-radius: 10px;
    display: block;
    padding:  20px;
    margin: 0;
    grid-column: 2;
    grid-row: 1;
}
.communicationMessageList li.client .date {
    grid-column: 2;
    grid-row: 2;
}
.communicationMessageList li.company {
    width: 80%;
    align-self: start;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 20px;
    row-gap: 10px;
}
.communicationMessageList li.company .image {
    grid-column: 2;
    grid-row: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    /* border: solid 1px #cccccc; */
    overflow: hidden;
}
.communicationMessageList li .date {
    color: #cccccc;
}
.communicationMessageList li.company .date {
    grid-row: 2;
    grid-column: 1;
}
.communicationMessageList li.company p {
    /* background-color: #d1e5f6; */
    border: solid 1pt #0B6FC7;
    border-radius: 10px;
    display: block;
    padding:  20px;
    margin: 0;
    grid-column: 1;
    grid-row: 1;
}
.communicationMessageList li.company p .temporary {
    display: inline-block;
    background-color: #d40000;
    color: #fff;
    padding: 3px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.communicationNewMessage {
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.communicationNewMessage > .action {
    text-align: center;
}

.communicationNewMessage > .message .recipient {
    margin-bottom: 10px;
}
.communicationNewMessage > .message .recipient .title {
    font-weight: bold;
    margin-right: 10px;
}
.communicationNewMessage > .message .recipient .title::after {
    content: ":";
}
.communicationNewMessage > .message .messageForm {
    position:relative;
    top: 0;
    left: 0;
}
.communicationNewMessage > .message .messageForm .activityIndicatorContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.communicationNewMessage > .message .messageForm .control {
    margin-top: 10px;
}
.communicationNewMessage > .message .messageForm .control .cancel {
    background-color: lightgray;
}
.communicationNewMessage > .message .messageForm .control .cancel:hover {
    background-color: red;
}
.communicationNewMessage > .message textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
    border: solid 1pt #78C700;
}
.communicationNewMessage > .message button.submit {
    background-color: #78C700;
}
.communicationNewMessage > .message button {
    margin-right: 10px;
}
.communicationNewMessage > .message button:hover {
    background-color: red;
}

.communicationNewMessage > .recipient .messageBox button.reload {
    background-color: #78C700;
    margin-right: 10px;
}
.communicationNewMessage > .recipient .messageBox button.reload:hover {
    background-color: red;
}
.communicationNewMessage > .recipient .recipientList {
    min-height: 240px;
    position: relative;
    top: 0;
    left: 0;
}
.communicationNewMessage > .recipient .recipientList .activityIndicatorContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.communicationNewMessage > .recipient ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.communicationNewMessage > .recipient li {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: solid 1pt #0B6FC7;
    border-radius: 10px;
    background-color: #fff;
}
.communicationNewMessage > .recipient li:hover {
    background-color: #f5f5f5;
}
.communicationNewMessage > .recipient li .image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
}
.communicationNewMessage > .recipient li .image img {
    width: 64px;
}
.communicationNewMessage > .recipient span.data {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
}
.communicationNewMessage > .recipient span.position {
    font-size: 10pt;
    color: #505050;
    white-space: nowrap;
}
.communicationNewMessage > .recipient span.name {
    margin-top: 5px;
    white-space: nowrap;
}
.communicationNewMessage > .recipient button.cancel {
    background-color: lightgray;
    margin-top: 15px;
}
.communicationNewMessage > .recipient button.cancel:hover {
    background-color: red;
}


@media only screen and (max-width: 767px) {
    /* .communication {
        padding-left: 10px;
        padding-right: 10px;
    } */
    .communication .motivation h3 {
        display: none;
    }
    .communication .layout {
        margin-top: 40px;
    }
    .communicationMessageList li.company {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    /* .communication {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .communication .motivation {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_message_135.svg);
    }
    .communication .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    } */
}