.service .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
}
.service > .activityIndicatorContainer {
    height: 80vh;
}
.service button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.service .messageBox p {
    color: red;
}
.service .messageBox.negative p {
    color: red;
}
.service .messageBox.pozitive p {
    color: green;
}
.service .messageBox button {
    background-color: lightgray;

}
.service .messageBox button:hover {
    background-color: red;
}

.serviceNewComplaint {
    position: relative;
    top: 0;
    left: 0;
}
.serviceNewComplaint button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.serviceNewComplaint button:hover {
    background-color: red;
}
.serviceNewComplaint .serviceForm {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}
.serviceNewComplaint .serviceForm form {
    display: contents;
}
.serviceNewComplaint .serviceForm form label {
    display: flex;
    flex-direction: column;
}
.serviceNewComplaint .serviceForm textarea {
    outline: none;
    appearance: none;
    padding: 3px;
    border-radius: 3px;
    border: solid 1px #cccccc;
    font-size: 12pt;
    line-height: 1.7em;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-top: 3px;
}
.serviceNewComplaint .serviceForm textarea:valid {
    padding-right: 27px;
    background-image: url('../common/images/valid.svg');
    background-position: right 3px center;
    background-repeat: no-repeat;
}
.serviceNewComplaint .activityIndicatorContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240,240,240,1);
}
.serviceNewComplaint .cancel {
    background-color: lightgray;
}
.serviceNewComplaint .message {
    margin-top: 40px;
}
.serviceNewComplaint .message textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    min-height: 200px;
    font-size: 11pt;
    font-family: inherit;
}
.serviceNewComplaint .message .control {
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .service {
        padding-left: 10px;
        padding-right: 10px;
    }
    .service .motivation h3 {
        display: none;
    }
    .serviceNewComplaint {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .serviceNewComplaint .severity ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .serviceNewComplaint .severity ul li {
        padding: 10px;
        box-sizing: border-box;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        border-radius: 5px;
    }
    .serviceNewComplaint .severity ul li.current {
        border-color: #0b6fc7;
    }
    .serviceNewComplaint .severity ul li .severity {
        display: block;
        line-height: 40px;
        font-size: 14pt;
        text-transform: capitalize;
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 36px auto;
        padding-left: 40px;
        margin-bottom: 5px;
    }
    .serviceNewComplaint .severity ul li.severityService .severity {
        background-image: url(images/servis_1.svg);
    }
    .serviceNewComplaint .severity ul li.severityDefect .severity {
        background-image: url(images/servis_2.svg);
    }
    .serviceNewComplaint .severity ul li.severityEmergency .severity {
        background-image: url(images/servis_3.svg);
    }
}

@media only screen and (min-width: 768px) {
    /* .service {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .service .motivation {
        grid-column: 1;
        grid-row: 1;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_servis_135.svg);
    }
    .service .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    } */
    .serviceNewComplaint {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .serviceNewComplaint .severity ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    .serviceNewComplaint .severity ul li {
        border: solid 4px #f8f8f8;
        border-radius: 10px;
        padding: 120px 20px 20px 20px;
        background-position: center 20px;
        background-repeat: no-repeat;
    }
    .serviceNewComplaint .severity ul li span {
        color: initial;
    }
    .serviceNewComplaint .severity ul li .severity {
        display: block;
        line-height: 2.5em;
        font-size: 14pt;
        text-transform: capitalize;
    }
    .serviceNewComplaint .severity ul li.severityService {
        background-image: url(images/servis_1.svg);
    }
    .serviceNewComplaint .severity ul li.severityDefect {
        background-image: url(images/servis_2.svg);
    }
    .serviceNewComplaint .severity ul li.severityEmergency {
        background-image: url(images/servis_3.svg);
    }
    .serviceNewComplaint .severity ul li.current {
        border-color: #0b6fc7;
    }
    .serviceNewComplaint .severity ul li:hover {
        background-color: #eeeeee;
    }
}