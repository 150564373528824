.activityIndicator {
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
}

/**
 * localActivityIndicator
 */
.localActivityIndicator {
    display: inline-block;
}
.localActivityIndicator:after {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: " ";
    display: block;
    border-radius: 50%;
    border-style: solid;
    border-color: rgb(50, 113, 230) transparent rgb(50, 113, 230) transparent;
    animation: localActivityIndicator 1.2s linear infinite;
}
@keyframes localActivityIndicator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.localActivityIndicatorSmall {
    width: 32px;
    height: 32px;
    padding: 3px;
}
.localActivityIndicatorSmall:after {
    border-width: 3px;
}
.localActivityIndicatorMedium {
    width: 48px;
    height: 48px;
    padding: 4px;
}
.localActivityIndicatorMedium:after {
    border-width: 5px;
}
.localActivityIndicatorBig {
    width: 64px;
    height: 64px;
    padding: 6px;
}
.localActivityIndicatorBig:after {
    border-width: 6px;
}

.localProgressBar {
    width: 100px;
    background-color: rgb(194, 194, 194);
}
.localProgressBar > div {
    height: 100%;
    background-color: rgb(50, 113, 230);
}
.localProgressBar.small {
    height: 10px;
}
.localProgressBar.medium {
    height: 20px;
}
.localProgressBar.big {
    height: 30px;
}


/**
 * Page
 */
 @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
 @import url('https://fonts.googleapis.com/css?family=Oxygen&subset=latin,latin-ext');
 
 
a, a:visited {
     text-decoration: none;
     color: #0b6fc7;
     cursor: pointer;
}
a, a:visited, a:hover {
    text-decoration: none;
}
h1, h2, h3, h4 {
    font-weight: normal;
}
button {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Oxygen',Calibri,Helvetica,Arial,sans-serif;
    background-color: #F8F8F8;
}
.page {
    width: 100%;
    padding: 0;
    margin: 0;
    /* display: grid; */
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 100vh;
}
.page > .content {
    padding: 80px 20px 60px 20px;
    overflow: hidden;
}

/**
 * Header
 */
.header {
    position: fixed;
    height: 60px;
    display: grid;
    /* grid-template-columns: 1fr 60px 60px 60px; */
    grid-template-columns: 1fr 60px;
    width: 100%;
    max-width: 1200px;
    border-bottom: solid 1pt #00000080;
    background-color: #F8F8F8;
    z-index: 2;
}
.header a {
    width: 60px;
    height: 60px;
    display: inline-block;
}
.header button {
    outline: none;
    cursor: pointer;
    border: none;
}
.header .logo {
    width: 100px;
    background: center center no-repeat url('images/logo.svg');
}
.header .profileButton {
    /* background: center center no-repeat url('images/profile.png'); */
}
.header .notificationButton {
    /* background: center center no-repeat url('images/bell.svg'); */
}
.header .menuButton {
    background: center center no-repeat url('images/burger.svg');
}

/**
 * Menu
 */
.menu {
    background-color: #ffffff;
    height: 100vh;
    left: 0;
    overflow: scroll;
    position: fixed;
    top: 0;
    transition: transform .25s ease-in-out;
    width: 100vw;
    z-index: 11;
}
.menu.open {
    transform: translate3d(0vw, 0, 0);
}
.menu.closed {
    transform: translate3d(-100vw, 0, 0);
}
.menu .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    background: center center no-repeat url('images/close_btn_48x48.svg');
    cursor: pointer;
    outline: none;
    border: none;
}
.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 70px 20px 0 20px;
}
.menu ul li {
    border-bottom: solid 1pt #00000047;
    display: flex;
    flex-direction: row;
}
.menu ul li:last-child {
    border-bottom: none;
}
.menu ul li .link {
    text-decoration: none;
    color: #000;
    font-size: 1.2em;
    font-weight: bold;
    padding: 20px;
    padding-left: 60px;
    flex: 1;
    background: 20px center no-repeat;
}
.menu ul li .link::first-letter {
    text-transform: uppercase;
}
.menu ul li.info .link {
    background-image: url('images/menu_icon_info.svg');
}
.menu ul li.notification .link {
    background-image: url('images/menu_icon_notification.svg');
}
.menu ul li.harmonogram .link {
    background-image: url('images/menu_icon_harmonogram.svg');
}
.menu ul li.diary .link {
    background-image: url('images/menu_icon_diary.svg');
}
.menu ul li.message .link {
    background-image: url('images/menu_icon_message.svg');
}
.menu ul li.file .link {
    background-image: url('images/menu_icon_files.svg');
}
.menu ul li.helpline .link {
    background-image: url('images/menu_icon_files.svg');
}
.menu ul li.loan .link {
    background-image: url('images/menu_icon_files.svg');
}

/**
 * Top button
 */
.topButton {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 10;
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: rgba(100,100,100,0.5);
    background-image: url('images/top_btn_48x48.svg');
}
.topButton.invisible {
    display: none;
}

/**
 * Breadcrumbs
 */
.breadcrumbs {
    margin-bottom: 20px;
}
.breadcrumbs ul {
    display: flex;
    flex-direction: row;
    font-size: 1.25em;
    text-transform: uppercase;
    align-items: center;
}
.breadcrumbs li:nth-last-child(n+3) {
    display: none;
}
.breadcrumbs li:nth-last-child(2) {
    display: flex;
}
.breadcrumbs li:nth-last-child(2) a {
    width: 32px;
    display: inline-block;
    height: 32px;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    background: center center no-repeat url('images/breadcrumb_back.svg');
}

/**
 * Card
 */
 .card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.card .header {
    height: 50px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding-left: 20px;
    padding-right: 20px;
}
.card .footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    justify-content: center;
    align-items: center;
    border-top: solid 1pt #00000080;
}
.card .footer .button {
    line-height: 50px;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #000;
    border: none;
    outline: none;
    font-size: inherit;
    padding: 0;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card .footer .button span {
    display: inline-block;
    margin-right: 5px;
}
.card .footer .button i {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.card .header .title {
    padding-left: 20px;
    line-height: 50px;
    font-weight: bold;
}

@media only screen and (min-width: 768px) {
    .page {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
    .menu {
        grid-column: 1;
        grid-row: 1;
        background-color: initial;
        height: initial;
        left: initial;
        overflow: initial;
        position: initial;
        top: initial;
        transition: initial;
        width: initial;
        z-index: initial;
        padding-top: 80px;
        padding-left: 20px;
    }
    .menu.open {
        transform: initial;
    }
    .menu.closed {
        transform: initial;
    }
    .menu .close {
        display: none;
    }
    .menu ul {
        background-color: initial;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
        border-radius: 10px;
        overflow: hidden;
        padding-top: 20px;
    }
    .header .profileButton {
        display: none;
    }
    .header .notificationButton {
        display: none;
    }
    .header .menuButton {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    
}
