.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.card .header {
    height: 50px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding-left: 20px;
    padding-right: 20px;
}
.card .footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    justify-content: center;
    align-items: center;
    border-top: solid 1pt #00000080;
}
.card .footer .button {
    line-height: 50px;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #000;
    border: none;
    outline: none;
    font-size: inherit;
    padding: 0;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card .footer .button span {
    display: inline-block;
    margin-right: 5px;
}
.card .footer .button i {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.card .header .title {
    padding-left: 20px;
    line-height: 50px;
    font-weight: bold;
}

.index .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.thermometer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 10px;
}
.thermometer .current {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
}
.thermometer .rest {
    display: block;
    margin-top: 5px;
}
.thermometer .scale {
    width: 100%;
    display: block;
    height: 10px;
    border-radius: 5px;
    background-color: #A3A3A3;
    margin-top: 10px;
    overflow: hidden;
}
.thermometer .scale .progress {
    width: 50%;
    background-color: #0B6FC7;
    padding-top: 10px;
}

.faze {
    margin-bottom: 10px;
}
.faze .header {
    background-color: #0B6FC7;
    color: #fff;
}
.faze .header .title {
    background: center left no-repeat url('images/card_icon_i.svg');
}
.faze .content {
    background-color: #fff;
    padding: 0 20px;
    margin-bottom: 20px;
    max-height: 40vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
}
.faze .content img {
    max-width: 100%;
}
.faze .content .image {
    max-height: 150px;
    object-fit: cover;
    object-position: top;
}
.faze .content .text {
    flex: 1;
    overflow: hidden;
}
.faze .content .text img {
    display: none;
}
.faze .footer .button i {
    /* background: center center no-repeat url('../common/images/arrow_next.svg'); */
}

.fazeStateSwiper {
    margin: 0 -20px;
    padding: 0 10px;
}

.fazeHistory {
    margin-bottom: 15px;
}
.fazeHistory .historyLink {
    padding-right: 15px;
    line-height: 35px;
    background: center right no-repeat url('../common/images/arrow_blue.svg');
    text-transform: uppercase;
    font-weight: bold;
}

.hurdle .header {
    background-color: #E31F23;
    color: #fff;
}
.hurdle .header .title {
    background: center left no-repeat url('images/card_icon_exc.svg');
}
.hurdle .content {
    background-color: #fff;
    padding: 20px;
    max-height: 30vh;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 70%, transparent);
    margin-bottom: 20px;
}
.hurdle .content h2 {
    font-size: 1.1em;
    font-weight: bold;
}
.hurdle .footer .button i {
    background: center center no-repeat url('../common/images/arrow_next.svg');
}

.harmonogram .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #00000047;
}
.harmonogram .header .title {
    background: center left no-repeat url('images/card_icon_harmonogram.svg');
    padding-left: 30px;
}
.harmonogram .content {
    background-color: #fff;
    padding: 0 20px;
}
.harmonogram .footer .button i {
    background: center center no-repeat url('../common/images/arrow_next.svg');
}
.harmonogram .content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.harmonogram .content li {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1pt #00000047;
}
.harmonogram .content li:last-child {
    border-bottom: none;
}
.harmonogram .content li.current span.title,
.harmonogram .content li.current span.date {
    font-size: 1.1em;
    font-weight: bold;
}
.harmonogram .content li span.warning {
    grid-column: 1 / 3;
    color: #E31F23;
}

.diary .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #00000047;
}
.diary .header .title {
    background: center left no-repeat url('images/card_icon_diary.svg');
    padding-left: 30px;
}
.diary .content {
    background-color: #fff;
    padding: 20px;
}
.diary .footer .button i {
    background: center center no-repeat url('../common/images/arrow_next.svg');
}

.communication .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #00000047;
}
.communication .header .title {
    background: center left no-repeat url('images/card_icon_message.svg');
    padding-left: 30px;
}
.communication .content {
    background-color: #fff;
    padding: 20px;
}
.communication .footer .button i {
    background: center center no-repeat url('../common/images/arrow_next.svg');
}
.communication .content .name {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
}
.communication .content .date {
    display: inline-block;
    font-size: 1.1em;
    /* color: #00000080; */
}
.communication .content .time {
    display: inline-block;
    font-size: 1.1em;
    margin-left: 10px;
}
.communication .content .text {
    margin-top: 10px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #00000047;
}
.file .header .title {
    background: center left no-repeat url('images/card_icon_file.svg');
    padding-left: 30px;
}
.file .content {
    background-color: #fff;
    padding: 20px;
}
.file .footer .button i {
    background: center center no-repeat url('../common/images/arrow_next.svg');
}
.file .content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.file .content li {
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    border-bottom: solid 1pt #00000047;
    /* padding-left: 50px; */
    /* background: center left no-repeat;
    background-size: 32px 32px; */
}
.file .content li:last-child {
    border-bottom: none;
}
/* .file .content li.pdf {
    background-image: url('../file/images/pdf-svgrepo-com.svg');
}
.file .content li .name {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
}
.file .content li .type {
    display: inline-block;
}
.file .content li .size {
    display: inline-block;
}
 */

/*
.index ul li.harmonogram a {
    background-image: url('../common/images/ico_schedule_135.svg');
}
.index ul li.message a {
    background-image: url('../common/images/ico_message_135.svg');
}
.index ul li.helpline a {
    background-image: url('../common/images/ico_director_135.svg');
}
.index ul li.file a {
    background-image: url('../common/images/ico_download_135.svg');
}
.index ul li.loan a {
    background-image: url('../common/images/ico_loans_135.svg');
}
.index ul li.profile a {
    background-image: url('../common/images/ico_profile_135.svg');
}
.index ul li.reference a {
    background-image: url('../common/images/ico_fee_gold_145.svg');
}
.index ul li.service a {
    background-image: url('../common/images/ico_servis_135.svg');
}

@media only screen and (min-width: 768px) {
    .index {
        margin-top: 40px;
    }
    .index h2 {
        display: none;
    }
    .index ul {
        margin-top: 80px;
        margin-bottom: 100px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        gap: 100px 0;
    }
    .index ul li {
        display: contents;
    }
    .index ul li a {
        padding-top: 150px;
        background-size: 150px 150px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .index ul li.reference {
        display: none;
    }
    .index .reference a {
        padding-left: 380px;
        background-size: 350px 250px;
        background-position: 0px top;
        background-repeat: no-repeat;
        background-image: url('../common/images/ico_fee_gold.svg');
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        margin: 0 auto;
        height: 250px;
        margin-top: 100px;
    }
    .index .reference a h3::first-letter {
        text-transform: uppercase;
    }
    .index .reference a .description {
        display: block;
        color: initial;
    }
}
@media only screen and (max-width: 767px) {
    .index {
        margin-top: 40px;
    }
    .index h2 {
        display: block;
        padding: 0 20px;
    }
    .index ul {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 30px 0;
    }
    .index ul li {
        display: contents;
    }
    .index ul li a {
        padding-top: 120px;
        background-size: 120px 120px;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .index > .reference {
        display: none;
    }
}
*/