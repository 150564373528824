.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.card .header {
    height: 50px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding-left: 20px;
    padding-right: 20px;
}
.card .footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    justify-content: center;
    align-items: center;
    border-top: solid 1pt #00000080;
}
.card .footer .button {
    line-height: 50px;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #000;
    border: none;
    outline: none;
    font-size: inherit;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card .footer .button span {
    display: inline-block;
    margin-right: 5px;
}
.card .footer .button i {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.card .header .title {
    padding-left: 20px;
    line-height: 50px;
    font-weight: bold;
}

.loan .motivation.card {
    margin-bottom: 30px;
}
.loan .motivation.card .content {
    padding: 0 20px;
}

.loan .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.loan button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.loan button:hover {
    background-color: red;
}

.loan .messageBox p {
    color: red;
}
.loan .messageBox.negative p {
    color: red;
}
.loan .messageBox.pozitive p {
    color: green;
}
.loan .messageBox button {
    background-color: lightgray;
}
.loan .messageBox button:hover {
    background-color: red;
}

.loanLoanList ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: min-content;
    gap: 20px;
}

.loanLoan {

}
.loanLoan.paid .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #00000047;
}
.loanLoan.expired .header {
    background-color: #E31F23;
    color: #fff;
}
.loanLoan.running .header {
    background-color: #0B6FC7;
    color: #fff;
}

/* .loanLoan {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 15px 20px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    background-color: lightgrey;
}
.loanLoan.paid {
    background-color: rgb(233, 233, 233);
}
.loanLoan.expired {
    background-color: rgb(250, 207, 207);
}
.loanLoan.running {
    background-color: rgb(221, 250, 207);
} */
.loanLoan .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 11pt;
    padding: 10px 20px;
    line-height: 30px;
}
.loanLoan .header,
.loanLoan .footer {
    display: block;
    padding: 0;
}
.loanLoan .line .title {
    padding: 0;
    line-height: initial;
}
.loanLoan .number {
    font-weight: bold;
}
.loanLoan.expired .currentAmount .value,
.loanLoan.running .currentAmount .value,
.loanLoan.expired .endDate .value {
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .loan {
        padding-left: 10px;
        padding-right: 10px;
    }
    .loan .motivation h3 {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    /* .loan {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .loan .motivation {
        grid-column: 1;
        grid-row: 1 / span 2;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_loans_135.svg);
    }
    .loan .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    } */
}