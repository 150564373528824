.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.card .header {
    height: 50px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding-left: 20px;
    padding-right: 20px;
}
.card .footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    justify-content: center;
    align-items: center;
    border-top: solid 1pt #00000080;
}
.card .footer .button {
    line-height: 50px;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #000;
    border: none;
    outline: none;
    font-size: inherit;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card .footer .button span {
    display: inline-block;
    margin-right: 5px;
}
.card .footer .button i {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.card .header .title {
    padding-left: 20px;
    line-height: 50px;
    font-weight: bold;
}

.harmonogram .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.harmonogram button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.harmonogram button:hover {
    background-color: red;
}

.harmonogram .messageBox p {
    color: red;
}
.harmonogram .messageBox.negative p {
    color: red;
}
.harmonogram .messageBox.pozitive p {
    color: green;
}
.harmonogram .messageBox button {
    background-color: lightgray;
}
.harmonogram .messageBox button:hover {
    background-color: red;
}



@media only screen and (min-width: 768px) {
    /* .harmonogram > .layout {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .harmonogramHarmonogram {
        grid-column: 1;
        padding-right: 40px;
    }
    .harmonogramSummary {
        grid-column: 2;
    } */
}

.harmonogramHarmonogram.card .header {
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}
.harmonogramHarmonogram.card .header .title {
    background: center left no-repeat url('../index/images/card_icon_harmonogram.svg');
    padding-left: 30px;
}
.harmonogramHarmonogram.card .content {
    padding: 20px;
}


.harmonogramStateList li {
    display: flex;
    flex-direction: column;
}
.harmonogramStateList li a {
    display: flex;
    flex-direction: column;
}
.harmonogramStateList li a > span {
    padding-left: 35px;
    display: block;
    margin-bottom: 4px;
}
.harmonogramStateList li a .date {
    background: left center no-repeat;
}
.harmonogramStateList li a .properties {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.harmonogramStateList li a .properties > span {
    display: inline-block;
}

.harmonogramStateList {
    margin: 0px 0 20px 0;
    list-style: none;
}
.harmonogramStateList > li:last-child > .link {
    border-bottom: none;
}
.harmonogramStateList > li.client.finished > .link .date {
    background-image: url('images/state_client_history.svg');
}
.harmonogramStateList > li.client.current:not(.hurdle) > .link .date,
.harmonogramStateList > li.client.future:not(.hurdle) > .link .date {
    background-image: url('images/state_client_future.svg');
}
.harmonogramStateList > li.company.finished > .link .date {
    background-image: url('images/state_company_history.svg');
}
.harmonogramStateList > li.company.current:not(.hurdle) > .link .date,
.harmonogramStateList > li.company.future:not(.hurdle) > .link .date {
    background-image: url('images/state_company_future.svg');
}
.harmonogramStateList > li.current.hurdle > .link .date,
.harmonogramStateList > li.future.hurdle > .link .date {
    background-image: url('images/state_common_event.svg');
}
.harmonogramStateList > li.finished > .link {
    color: #b0b0b0;
}
.harmonogramStateList > li.future .link,
.harmonogramStateList > li.current .link {
    color: #333333;
}
.harmonogramStateList > li:is(.finished,.current,.future) > a:hover {
    background-color: #fffbeb;
}
.harmonogramStateList > li .date {
    background-position: left center;
    background-repeat: no-repeat;
    font-size: 11pt;
    line-height: 22px;
    font-weight: bold;
}
.harmonogramStateList > li .link {
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url('images/arrow.svg');
    border-bottom: solid 1px #c0c0c0;
    padding: 10px 10px 10px 0;
}
.harmonogramStateList > li .properties {
    display: flex;
    flex-direction: row;
    font-size: 10pt;
}
.harmonogramStateList > li .properties > * {
    margin-right: 5px;
}
.harmonogramStateList > li .properties .delay {
    padding: 1px 8px;
    border-radius: 3px;
}
.harmonogramStateList > li .properties .delay.company {
    border: solid 1pt #0B6FC7;
    color: #0B6FC7;
}
.harmonogramStateList > li .properties .delay.client {
    border: solid 1pt #78C700;
    color: #78C700;
}
.harmonogramStateList > li .properties .delay.god {
    border: solid 1pt #e0c215;
    color: #e0c215;
}
.harmonogramStateList > li .properties .icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}
.harmonogramStateList > li .properties .icon.clima {
    background-image: url('images/icon_clima.svg');
}
.harmonogramStateList > li .properties .icon.hurdle {
    background-image: url('images/icon_hurdle.svg');
}
.harmonogramStateList > li.summary {
    padding-top: 10px;
}
.harmonogramStateList > li.summary > span {
    padding-left: 35px;
    font-weight: bold;
}
.harmonogramStateList > li.summary .date {
    color: #000;
}
.harmonogramStateList > li.summary .name {
    color: #0B6FC7;
}


.harmonogramSummary .card .header {
    color: #fff;
    padding-left: 0;
}
.harmonogramSummary .card ul {
    list-style: none;
    padding: 0 20px;
    margin: 0;
}
.harmonogramSummary .card li {
    padding: 0;
    margin: 0;
    border-bottom: 1pt solid #bababa;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.harmonogramSummary .card li:last-child {
    border-bottom: none;
}
.harmonogramSummary .card li .title {
    padding: 3px 0;
}
.harmonogramSummary .card li.summary .title {
    font-weight: bold;
}
.harmonogramSummary .card li .value {
    color: #fff;
    padding: 3px 5px;
    border-radius: 3px;
    display: block;
    width: 60px;
    flex-shrink: 0;
    text-align: center;
}
.harmonogramSummary .card.company .header,
.harmonogramSummary .card.company .value {
    background-color: #0B6FC7;
}
.harmonogramSummary .card.client .header,
.harmonogramSummary .card.client .value {
    background-color: #78C700;
}
.harmonogramSummary .card.god .header,
.harmonogramSummary .card.god .value {
    background-color: #F5B000;
}

/* .harmonogramNoticeList .notice {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.harmonogramNoticeList .notice h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.harmonogramNoticeList .notice.hurdle h3 {
    background-image: url('images/notice_hurdle.svg');
}
.harmonogramNoticeList .notice.cooperation h3 {
    background-image: url('images/notice_cooperation.svg');
}
.harmonogramNoticeList .notice.clima h3 {
    background-image: url('images/notice_clima.svg');
}
.harmonogramNoticeList .notice p {
    font-size: 10pt;
} */

.harmonogramNoticeList .card .header {
    color: #fff;
    padding-left: 0;
    background-color: #E31F23;
}
.harmonogramNoticeList .card .content {
    padding: 4px 20px;
    max-height: 30vh;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 70%, transparent);
    margin-bottom: 20px;
}

.harmonogramHurdle .card .content {
    max-height: 30vh;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 70%, transparent);
    margin-bottom: 20px;
}

.state .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.state button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.state button:hover {
    background-color: red;
}

.state .messageBox p {
    color: red;
}
.state .messageBox.negative p {
    color: red;
}
.state .messageBox.pozitive p {
    color: green;
}
.state .messageBox button {
    background-color: lightgray;
}
.state .messageBox button:hover {
    background-color: red;
}

.state .name {
    padding-right: 36px;
    background-position: right top;
    background-repeat: no-repeat;
    line-height: 27px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: bold;
}
.state .name::first-letter {
    text-transform: uppercase;
}
.state .name.company.finished {
    background-image: url('images/state_big_company_history.svg');
}
/* .state .name.company.current {
    background-image: url('images/state_company_current.svg');
}
.state .name.company.future {
    background-image: url('images/state_company_future.svg');
} */
.state .name.client.finished {
    background-image: url('images/state_big_client_history.svg');
}
/* .state .name.client.current {
    background-image: url('images/state_client_current.svg');
}
.state .name.client.future {
    background-image: url('images/state_client_future.svg');
} */
.state .name.hurdle:not(.finished) {
    background-image: url('images/state_big_common_event.svg');
}


/* .state .notice {
    border-radius: 5px;
    background-color: #fff6d5;
    color: #554400;
    padding: 10px;
    margin: 15px 0;
}
.state .notice h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: left center;
}
.state .notice.hurdle h3 {
    background-image: url('images/notice_hurdle.svg');
}
.state .notice.cooperation h3 {
    background-image: url('images/notice_cooperation.svg');
}
.state .notice.clima h3 {
    background-image: url('images/notice_clima.svg');
}
.state .notice p {
    font-size: 10pt;
} */

.state .notice .header {
    color: #fff;
    padding-left: 0;
    background-color: #E31F23;
}
.state .notice.cooperation .header {
    background-color: initial;
    border: 2px solid #E31F23;
    border-radius: 10px 10px 0 0;
    box-sizing: border-box;
    color: #E31F23;
}
.state .notice .content {
    padding: 4px 20px;
    max-height: 30vh;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 70%, transparent);
    margin-bottom: 20px;
}

.state .time {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.state .time .header {
    height: 30px;
}
.state .time .header .title {
    color: #fff;
    font-size: 11pt;
    line-height: initial;
}
.state .time .content {
    padding: 20px;
    text-align: center;
    font-weight: bold;
}
.state .time > .card {
    flex: 1;
    margin-bottom: 0;
}
.state .time.client .header {
    background-color: #78C700;
}
.state .time.company .header {
    background-color: #0B6FC7;
}

/* .state .time > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
} */
/* .state .time .date {
    font-size: 16pt;
    color: #085294;
    margin-bottom: 10px;
} */
/* .state .time > .end.delay {
    color: #803300;
} */

.state .log .header {
    padding-left: 0;
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}
.state .log .content {
    padding: 4px 20px;
}
.state .log {
    margin-top: 40px;
}
.state .log > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
}
.state .log > p {
    font-size: 11pt;
}

.state .hurdleList {
    /* margin-top: 40px; */
}
.state .hurdleList ul {
    display: flex;
    flex-direction: column;
}
.state .hurdleList li {
    display: contents;
}
.state .hurdleList li .link {
    flex: 1;
    color: inherit;
    background: right center no-repeat url('images/arrow.svg');
    border-bottom: solid 1pt #0000002c;
    padding: 20px 20px 20px 0;
}
.state .hurdleList li:last-child .link {
    border-bottom: none;
}
.state .hurdleList li .link .start {
    font-weight: bold;
}
.state .hurdleList li .link .end {
    font-weight: bold;
}
.state .hurdleList li .link .end::before {
    content: ' - ';
}
.state .hurdleList .content li .link p {
    margin: 10px 0 0 0;
}
.state .hurdleList .header {
    padding-left: 0;
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}
.state .hurdleList > .content {
    padding: 0 20px;
}



.state .delay {
    /* margin-top: 40px; */
}
.state .delay .header {
    padding-left: 0;
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}
.state .delay .content {
    padding: 20px;
}
/* .state .delay > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
} */
.state .delay .content > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 3px;
}
.state .delay .content > div > .length {
    padding: 1px 0;
    /* color: #ffffff; */
    border-radius: 3px;
    margin-right: 5px;
    width: 60px;
    text-align: center;
}
.state .delay .content > div > .title {
    padding: 2px;
    flex: 1;
}
.state .delay .content > div.company > .length {
    color: #0B6FC7;
    border: solid 1pt #0B6FC7;
}
.state .delay .content > div.company > .title {
    /* color: #0b6fc7; */
}
.state .delay .content > div.client > .length {
    color: #78C700;
    border: solid 1pt #78C700;
}
.state .delay .content > div.client > .title {
    /* color: #0bc74c; */
}
.state .delay .content > div.god > .length {
    color: #F5B000;
    border: solid 1pt #F5B000;
}
.state .delay .content > div.god > .title {
    /* color: #e0c215; */
}

.state .description {
    /* margin-top: 40px; */
}
.state .description .header {
    padding-left: 0;
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}
.state .description .content {
    padding: 4px 20px;
}
/* .state .description .content > h3 {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
} */
.state .description .content > p {
    font-size: 11pt;
    white-space: pre-wrap;
}
.state .description .content > p::first-letter {
    text-transform: uppercase;
}

.hurdleContent .card .content {
    padding: 0 20px;
}
.hurdleContent .card.hurdle .header {
    color: #fff;
    padding-left: 0;
    background-color: #E31F23;
}
.hurdleContent .card.term .header,
.hurdleContent .card.note .header {
    padding-left: 0;
    background-color: #F8F8F8;
    border-bottom: solid 1pt #0000002c;
}