.card {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.card .header {
    height: 50px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    padding-left: 20px;
    padding-right: 20px;
}
.card .footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    justify-content: center;
    align-items: center;
    border-top: solid 1pt #00000080;
}
.card .footer .button {
    line-height: 50px;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #000;
    border: none;
    outline: none;
    font-size: inherit;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card .footer .button span {
    display: inline-block;
    margin-right: 5px;
}
.card .footer .button i {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.card .header .title {
    /* padding-left: 20px; */
    line-height: 50px;
    font-weight: bold;
}

.faze .activityIndicatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(240,240,240,1);
    height: 80vh;
}

.faze button {
    background-color: #0b6fc7;
    padding: 10px 20px;
    font-size: 11pt;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
}
.faze button:hover {
    background-color: red;
}

.faze .messageBox p {
    color: red;
}
.faze .messageBox.negative p {
    color: red;
}
.faze .messageBox.pozitive p {
    color: green;
}
.faze .messageBox button {
    background-color: lightgray;
}
.faze .messageBox button:hover {
    background-color: red;
}

.fazeState {
    /* width: 100%; */
    margin-bottom: 20px;
    /* margin-left: -20px;
    margin-right: -20px; */
}
.fazeState .fazeStateSwiper {
    padding: 0 10px;
    margin: 0 -20px;
}

.fazeStateCard {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}
.fazeStateCard .content {
    height: 45vh;
    max-width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
}
.fazeStateCard .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.fazeStateCard .content .image {
    max-height: 160px;
    object-fit: cover;
    object-position: top;
}
.fazeStateCard .content .text {
    flex: 1;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 70%, transparent);
}
.fazeStateCard .content .text img {
    display: none;
}
.fazeStateCard .content img {
    max-width: 100%;
}
.fazeStateCard .header {
    background-color: #0B6FC7;
    color: #fff;
}

.cardContent .header {
    background-color: #0B6FC7;
    color: #fff;
}
.cardContent .content {
    padding: 0 20px 30px 20px;
}
.cardContent .content img {
    max-width: 100%;
}


@media only screen and (max-width: 767px) {
    .faze {
        /* padding-left: 10px;
        padding-right: 10px; */
    }
    .faze .motivation {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    /* .loan {
        display: grid;
        grid-template-columns: 408px 1fr;
        gap: 20px;
    }
    .loan .motivation {
        grid-column: 1;
        grid-row: 1 / span 2;
        padding-top: 200px;
        border-right: solid 10px #eeeeee;
        padding-right: 30px;
        padding-left: 20px;
        background-position: center 50px;
        background-repeat: no-repeat;
        background-image: url(../common/images/ico_loans_135.svg);
    }
    .loan .layout {
        grid-column: 2;
        grid-row: 1;
        padding: 10px;
    } */
    .fazeStateList {
        /* max-width: 500px; */
        flex: 1;
    }
    .fazeState .fazeStateSwiper {
        padding-bottom: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .faze .motivation {
        display: none;
    }
}